.TableHead {
    display: flex !important;
    flex-direction: column;
}

.MainTable {
    border-collapse: separate !important;
    border-spacing: 0px 17px !important;
}

.tableContainer {
    width: 100%;
    overflow-x: scroll;
}

.menuListHolder ul {
    padding: 0 !important;
}

/* .tableContainer::-webkit-scrollbar {
    display: none;
} */

.table_bg {
    width: 100%;
    align-items: center;
    background: white;
    border-radius: 10px !important;
    padding: 10px;
}

.menuItems {
    min-width: 132px !important;
}

.menuItems:hover {
    background-color: #EAF6FF !important;
}

.promoListStatusContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ListItemButton svg {
    color: black !important;
}

.table_bg1 {
    width: 100%;
    align-items: center;
    background: #fff;
    border-radius: 10px !important;
    padding: 10px;
}

.table_bg1 td {
    min-width: 180px;
}

.SubTable {
    background: #F3F8FC;
    border-radius: 10px !important;
}

.tableHeadRow {
    width: 100%;
    /* display: flex !important; */
    align-items: center;
    background: #d7edff;
    border-radius: 10px !important;
}

.tableHeadCell {
    font-size: 15px !important;
    font-weight: 600 !important;
    line-height: 22px !important;
    text-align: left;
    color: #0a1f41 !important;
    min-width: 180px;
    border: none !important;
    padding: 16px !important;
}


.tableHEadercelldiv {
    display: flex;
    align-items: center;
}

.TableSubRow td,
.TableSubRow th {
    padding: 16px !important;
    border-bottom: none !important;
}

.TableSubRow th {
    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: 19px !important;
    color: #0A1F41;
}

.someSmallText {
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 16px !important;
    color: #6B778C;
}

.TabPanel>div {
    padding: 0 !important;
}

.IndexMainButton {
    text-transform: none !important;
    font-size: 18px !important;
    font-weight: 600 !important;
    line-height: 22px !important;
    padding: 12px 24px !important;
}

.IndexMainButton svg {
    width: 21px;
    margin-right: 5px !important;
}

.brudCrumb {
    /* width: 210px; */
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    margin-top: 10px;
    column-gap: 15px;
    cursor: pointer;
}

.brudCrumb svg {
    color: black;
}

.addPromoMainHeading {
    font-size: 18px !important;
    font-weight: 600 !important;
    line-height: 25px !important;
    color: #0A1F41;
}

.brudcrumback {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 18px !important;
    color: #0A1F41;
}

.displayEnd {
    display: flex;
    justify-content: flex-end;
    column-gap: 25px;
    margin-top: 20px;
}

.displayEnd button {
    width: 178px !important;
    padding: 10px !important;
}

.form_lable {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 18px !important;
    color: #6B778C;
}

.inputgridcontainer {
    display: grid;
    grid-template-columns: 32% 32% 32%;
    column-gap: 2%;
}

.datePicker1 {
    position: relative;
}

.datePicker1>div {
    width: 100%;
}

.datePicker1>div>div>input {
    padding: 8px 10px;
    border-radius: 4px;
    border: 1px solid #bfd1cf;
    width: 100%;
}

.datePickerCalendarIcon {
    right: 5px;
    position: absolute;
    top: 10px;
}