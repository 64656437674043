.teamMemberSearch {
  width: 360px;
}

.teamMemberSearch > div {
  border-radius: 25px;
  background: white;
  padding: 3px 14px;
}

.AdduserMainBtn {
  font-size: 18px !important;
  font-weight: 500 !important;
  line-height: 22px !important;
  text-transform: none !important;
}

.AdduserMainBtn span {
  margin-right: 7px;
}

.HeaderSearchBar {
  display: flex;
  justify-content: space-between;
}

.TableContainer {
  overflow-x: scroll;
}

/* .TableContainer::-webkit-scrollbar {
    display: none;
} */

.MainTable {
  border-collapse: separate !important;
  border-spacing: 0px 17px !important;
}

.tableHeadRow {
  width: 100%;
  /* display: flex !important; */
  align-items: center;
  background: #d7edff;
  border-radius: 10px !important;
}

.tableHeadCell {
  font-size: 15px !important;
  font-weight: 600 !important;
  line-height: 22px !important;
  text-align: left;
  color: #0a1f41 !important;
  min-width: 180px;
  border: none !important;
  padding: 16px !important;
}

.tableHEadercelldiv {
  display: flex;
  align-items: center;
}

.displayFlex {
  display: flex;
  align-items: center;
  column-gap: 5px;
}

.table_bg1 {
  width: 100%;
  align-items: center;
  background: #fff;
  border-radius: 10px !important;
  padding: 10px;
}

.table_bg1 td {
  border-bottom: none !important;
  min-width: 180px;
}

.InputForm {
  margin: 25px 0 0px;
}

.InputFormGridContainer {
  display: grid;
  grid-template-columns: 32% 32% 32%;
  column-gap: 2%;
}

.formLable {
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  margin-bottom: 5px !important;
  color: #6b778c;
}

.FormHEader {
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 25px !important;
  color: #0a1f41;
}

.custom_label {
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  color: #1d2125;
}

.AddUSerBtnSection {
  display: flex;
  justify-content: flex-end;
  column-gap: 15px;
}

.AddUSerBtnSection button {
  width: 178px;
  height: 48px;
}

.closebtn {
  position: absolute !important;
  right: 5px;
  top: 5px;
}

.closebtn svg {
  font-size: 21px;
  font-weight: 800;
  color: black;
}

.imagePageText {
  /* margin-top: 20px !important; */
  text-align: center;
  font-size: 24px !important;
  font-weight: 600 !important;
  line-height: 34px !important;
  /* width: 75%; */
}

.addMemberModalbtnSection {
  display: flex;
  margin: 25px auto 0px;
  justify-content: space-between;
  width: 100%;
}

.addMemberModalbtnSection button {
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 22px !important;
  width: 48%;
  padding: 11px 24px;
  text-transform: capitalize !important;
}

.successPageSubText {
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  text-align: center;
  color: #6b778c;
}

.successPageMainDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 20px;
}

.brudCrumb {
  /* width: 210px; */
  display: flex;
  align-items: center;
  column-gap: 15px;
}

.brudCrumb svg {
  color: black;
}

.brudCrumb span {
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
}

.brudCrumb span:hover {
  text-decoration: underline;
  color: blue;
}

.datePicker1 {
  position: relative;
}

.datePicker1 > div {
  width: 100%;
}

.datePicker1 > div > div > input {
  padding: 8px 10px;
  border-radius: 4px;
  border: 1px solid #bfd1cf;
  width: 100%;
}

.datePickerCalendarIcon {
  right: 5px;
  position: absolute;
  top: 10px;
}
