.wrapper {
    border: 3px solid blue;
}

.container {
    background-color: pink;
}

.barCompleted {
    background-color: lightblue;
    width: 80%;
}

.label {
    font-size: 20px;
    color: green;
}



.notificationBar.MuiTooltip-popper.MuiTooltip-popperInteractive.css-sq6nry-MuiPopper-root-MuiTooltip-popper.MuiPopper-root {}