.AddNotificationMainBtn {
    font-size: 18px !important;
    font-weight: 500 !important;
    line-height: 22px !important;
    text-transform: none !important;
}

.AddNotificationMainBtn span {
    margin-right: 7px;
}

.TabPanel>div {
    padding: 24px 0;
}

.TicketsPageMaindiv {
    display: grid;
    grid-template-columns: 35% 65%;
    min-height: 100vh;
}

.ContactListPAge {
    border-right: 1px solid #DADEF2;
    padding: 15px;
    position: relative;
}

.displayFlex {
    display: flex;
    align-items: center;
    column-gap: 5px;
}

.TableCellMainText {
    font-size: 15px !important;
    font-weight: 400 !important;
    line-height: 18px !important;
    color: #0A1F41;
}

.SomeOtheText {
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 16px !important;
    color: #6B778C;
}

.fileName {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 17px !important;
    letter-spacing: 0px;
    text-align: left;
    color: #0A1F41;
}

.cardContainer {
    width: 320px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
}

.SomeOtherTextclosedBy {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    color: #6B778C;
}

.TableCellMainText1 {
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 22px !important;
    color: #0A1F41;
}

.SomeOtheText1 {
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 22px !important;
    color: #4E5365;
}

.ContactListPAgeSubDiv {
    padding: 15px;
    position: relative;
    cursor: pointer;
}

.headerText {
    font-size: 18px !important;
    font-weight: 600 !important;
    line-height: 25px !important;
    color: #0A1F41;
}

.StatusChip {
    position: absolute;
    top: 10px;
    right: 0;
}

.MessagePageSubDiv {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
}

.MessagePageHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 15px;
    border-bottom: 1px solid #DADEF2;
}

.MessagePageHeaderContainer {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    border-bottom: 1px solid #DADEF2;
}

.MeaggseSubHeaderHeading {
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 22px !important;
    color: #6B778C;
}

.messagefrom {
    font-weight: 400 !important;
}

.messagefrom1 {
    font-weight: 400 !important;
    color: #227DC3 !important;
}

.messagefrom2 {
    font-weight: 400 !important;
}

.displayFlexContainer {
    display: flex;
    align-items: center;
    column-gap: 10px;
}

.imageContainer {
    background: #0302290F;
    min-width: 50%;
    width: max-content;
    padding: 10px;
    justify-content: space-between;
    border-radius: 5px;
}

.formLable {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 18px !important;
    margin-bottom: 5px !important;
    color: #6B778C;
}

.InputGridContainer {
    display: grid;
    grid-template-columns: 32% 32% 32%;
    column-gap: 2%;
}

.DropZoneContainer {
    border: 1px dashed rgba(81, 111, 144, 0.4);
    height: fit-content;
}

.DropZone {
    min-height: 96px;
    height: auto;
    background: #f3f8fc;
    border-radius: 8px;
    display: flex;
    column-gap: 10px;
    align-items: center;
}

.DropzoneBtn {
    padding: 8px 22px !important;
    border-radius: 4px !important;
    width: 150px;
    margin-left: 30px !important;
    text-transform: capitalize !important;
}

.dropDownimageContainer {
    display: flex;
    column-gap: 2%;
    padding: 15px 20px;
    /* margin-bottom: 30px; */
}

.subImageContainer {
    height: auto;
    width: 250px;
}

.imageText {
    display: flex;
    justify-content: space-between;
}

.createTicketBtnsection {
    display: flex;
    justify-content: flex-end;
    column-gap: 15px;
}

.createTicketBtnsection button {
    width: 179px;
    height: 48px;
}

.ContaxtList {
    /* max-height: 560px; */
    overflow-y: scroll;
}

.ContaxtList::-webkit-scrollbar {
    display: none;
}

.PaginationDiv {
    background: #f4f5f7;
    padding: 6px;
    border-radius: 10px;
    position: absolute;
    width: calc(100% - 30px);
    bottom: 15px;
}

.PaginationDiv>div {
    margin-top: 0 !important;
}

.DetailsInputFeils {
    margin-bottom: 0 !important;
}