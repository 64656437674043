.LoginPageMaindiv {
  display: grid;
  grid-template-columns: 45% 55%;
  width: 80%;
  margin: auto;
}

.maindivForlogin {
  display: grid;
  place-items: center;
  height: 100vh;
}

.LoinPageImageDiv {
  background-image: url("../../Assets/images/loginPagesideimage.png");
  background-size: cover;
}

.ImagePageMainHeader {
  margin: 35px 0px 5px;
  font-size: 49px !important;
  font-weight: 600 !important;
  line-height: 55px !important;
  letter-spacing: -2px !important;
  color: #fff;
  width: 80%;
}

.ImagePageSubHeader {
  font-size: 18px !important;
  font-weight: 400 !important;
  line-height: 30px !important;
  color: #fff;
  width: 100%;
  margin: 10px auto;
}

.LoginPgeTextdiv {
  width: 100%;
  margin: auto;
}

.snackBar {
  top: -410px !important;
  /* right: 12px !important; */
  left: 70% !important;
}

.snackBar svg {
  color: #5f2120 !important;
}

.LoginForm {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 20px auto;
}

.passwordInputContainer {
  position: relative;
}

.loginForgotContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0 25px;
}

.checkBoxContainer {
  display: flex;
  column-gap: 10px;
}

.showPassword {
  position: absolute;
  right: 0;
  top: 2px;
}

.LoginPAgeMainHEader {
  font-size: 32px !important;
  font-weight: 600 !important;
  line-height: 45px !important;
  letter-spacing: -2px !important;
  text-align: left;
  color: #090914;
}

.loginDescription {
  font-size: 17px !important;
  font-weight: 400 !important;
  line-height: 30px !important;
  color: #6b778c;
}

.form_lable {
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  color: #6b778c;
}

.RemeberTextLogin {
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 22px !important;
}

.ForgetPasswordbtn {
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 17px !important;
  color: #227dc3;
  text-transform: none !important;
}

.LoginPageMainbtn {
  text-transform: none !important;
  padding: 12px 0;
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 22px !important;
  height: 56px;
}

.forgetPasswordmodalclose {
  display: flex;
  justify-content: flex-end;
}

.forgetPasswordmodalclose svg {
  color: black;
  width: 25px;
  height: 25px;
}

.ModalHeadingSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  margin: 10px auto;
}

.modalHeadind {
  font-weight: 600 !important;
  font-size: 24px !important;
  align-items: center;
  text-align: center;
  color: #0a1f41;
}

.modalHeadin2 {
  font-weight: 400 !important;
  font-size: 16px !important;
  color: #6b778c;
}

.modalHeadin2 span {
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 24px !important;
  color: #0a1f41;
}

.depFormContainer {
  margin: 20px 0;
}

.OtpFeildsContainer {
  justify-content: flex-start !important;
}

.OtpFeildsContainer>div {
  justify-content: space-between !important;
  width: 280px !important;
}

.OtpFeildsContainer input {
  margin: 0 !important;
  width: 60px !important;
  height: 60px !important;
}