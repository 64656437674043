.DashboardFirstContainer {
  display: flex;
  column-gap: 2%;
}

.Pichart1Container {
  width: 30%;
  padding: 20px;
  padding-bottom: 0 !important;
}

.piechartHEadingMain {
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 25px !important;
  color: #0a1f41;
}

.pieChartSubHEading {
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  color: #6b778c;
}

.secondPie tspan {
  font-size: 85px !important;
}

.secondPie tspan + tspan {
  font-size: 40px !important;
}

.piechartValuyeText {
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 21px !important;
  color: #0a1f41;
}

.Pichart2Container {
  width: 68%;
  padding: 20px;
  padding-bottom: 0 !important;
}

.displayFlex {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.TwoPieChartContainer {
  display: flex;
  margin-top: 26px;
}

.DashboardSecondContainer {
  display: flex;
  column-gap: 2%;
  margin-top: 20px;
}

.Pichart3Container {
  width: 49%;
  padding: 20px;
  padding-bottom: 0 !important;
}

.secondPie {
  position: absolute;
  left: 25px;
}

.firstPieChartSubChart {
  position: absolute;
  left: 30px;
}

.TabPanel > div {
  padding: 0 0;
}

.ViewAllBtn {
  position: absolute !important;
  right: 0;
  top: 8px;
  text-transform: none !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 17px !important;
  cursor: pointer !important;
  z-index: 99;
}

.datePicker1 {
  position: relative;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin: 0px 0 15px;
}

.datePicker1 input {
  padding: 10px;
  background: white;
}

.datePickerCalendarIcon {
  right: 5px;
  position: absolute;
  top: 8px;
}
