.TabPanel>div {
    padding: 24px 0;
}

.tableHeadRow {
    width: 100%;
    /* display: flex !important; */
    align-items: center;
    background: #d7edff;
    border-radius: 10px !important;
}

.tableHeadCell {
    font-size: 15px !important;
    font-weight: 600 !important;
    line-height: 22px !important;
    text-align: left;
    color: #0a1f41 !important;
    border: none !important;
    padding: 16px !important;
}

.tableHEadercelldiv {
    display: flex;
    align-items: center;
}

.displayFlex {
    display: flex;
    align-items: center;
    column-gap: 5px;
}

.table_bg1 {
    width: 100%;
    align-items: center;
    background: #fff;
    border-radius: 10px !important;
    padding: 10px;
    border-bottom: 1px solid #DADEF2;
}

.table_bg1 td {
    border-bottom: none !important;
    padding: 20px 16px 30px;
}

.TableCellMainText {
    font-size: 15px !important;
    font-weight: 400 !important;
    line-height: 18px !important;
    color: #0A1F41;
}

.SomeOtheText {
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 16px !important;
    color: #6B778C;
}

.ReadMoreToggleButton {
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    color: #227DC3;
}

.SomeOtherTextclosedBy {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    color: #6B778C;
}

.TableCellMainText1 {
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 22px !important;
    color: #0A1F41;
}

.SomeOtheText1 {
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 22px !important;
    color: #4E5365;
}

.feedbackEmojiContainer {
    display: flex;
    column-gap: 5px;
}

.feedbackEmoji {
    opacity: 0.5;
}

.documentImagesContiner {
    display: flex;
    column-gap: 10px;
}

.documentImagesContiner img {
    width: 40px;
    height: 40px;
    border-radius: 5px;
}

.documentImagesContiner p {
    color: white;
    width: 40px;
    height: 40px;
    background: #227DC3;
    padding: 10px;
    border-radius: 5px;
}