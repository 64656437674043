.displayFlex {
    display: flex;
    align-items: center;
    column-gap: 5px;
}

.MainCardDiv {
    min-height: 550px;
}

.MainCardDiv svg {
    color: #0A1F41;
    font-size: 18px;
}

.PackageNameMain {
    font-size: 20px !important;
    font-weight: 600 !important;
    line-height: 27px !important;
    color: #0A1F41;
}

.packageSmallHEading {
    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: 19px !important;
    color: #0A1F41;
}

.nrmlfontweight {
    font-weight: 400 !important;
}

.login_form_lable {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 19px !important;
    color: #6B778C;
}

.MenuContainer ul {
    padding: 0 !important;
    min-width: 130px;
}

.MenuItems:hover {
    background: #EAF6FF;
    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: 19px !important;
    color: #227DC3;
}

.brudCrumb {
    /* width: 210px; */
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    column-gap: 15px;
    cursor: pointer;
}

.brudCrumb svg {
    color: black;
}

.addSubscHeading {
    font-size: 18px !important;
    font-weight: 600 !important;
    line-height: 25px !important;
    color: #0A1F41;
}

.addSubscSubHeading {
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 22px !important;
    color: #0A1F41;
}

.deleteButton {
    padding: 0px;
    min-width: 0px;
    margin-top: 10px;
}

.deleteButton svg path {
    fill: red;
}

.addButton {
    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: 17px !important;
    text-transform: none !important;
}

.addButton svg {
    width: 16px;
    margin-right: 10px;
}

.addButton svg path {
    fill: #227DC3
}

.AddSubsButtonSection {
    display: flex;
    justify-content: flex-end;
    column-gap: 20px;
}

.AddSubsButtonSection button {
    min-width: 180px;
    padding: 12px 24px;
}

.addSubsButton svg path {
    fill: white !important;
}

.closebtn {
    position: absolute !important;
    right: 5px;
    top: 5px;
}

.closebtn svg {
    font-size: 21px;
    font-weight: 800;
    color: black;
}

.modalHeadind {
    font-size: 24px !important;
    font-weight: 600 !important;
    line-height: 34px !important;
    color: #0A1F41;
}

.modalSubHeading {
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    color: #6B778C;
}

.SomeOtheText {
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 16px !important;
    color: #6B778C;
}