.AddNotificationMainBtn {
    font-size: 18px !important;
    font-weight: 500 !important;
    line-height: 22px !important;
    text-transform: none !important;
}

.AddNotificationMainBtn span {
    margin-right: 7px;
}

.SomeOtheText {
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 16px !important;
    color: #6B778C;
}

.errorText {
    font-weight: 400 !important;
    font-size: 0.75rem !important;
    line-height: 1.66 !important;
    letter-spacing: 0.03333em !important;
    text-align: left;
    margin-top: 4px;
    margin-right: 14px;
    margin-bottom: 0;
    margin-left: 14px;
    color: red;
}

/* .NoDataImagePagePaper {} */

.chips_container {
    display: flex;
    column-gap: 10px;
}

.NoDataImageMindiv {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.NoDataPageMainText {
    font-size: 24px !important;
    font-weight: 600 !important;
    line-height: 34px !important;
    color: #0A1F41;
    width: 535px;
    text-align: center;
}

.brudCrumb {
    /* width: 210px; */
    display: flex;
    align-items: center;
    column-gap: 15px;
}

.brudCrumb svg {
    color: black;
}

.brudCrumb span {
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
}

.brudCrumb span:hover {
    text-decoration: underline;
    color: blue;
}

.FormHEader {
    font-size: 18px !important;
    font-weight: 600 !important;
    line-height: 25px !important;
    color: #0A1F41;
}

.chipBtn {
    margin-right: 10px;
}

.viewChipBtn {
    height: 29px;
}

.formLable {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 18px !important;
    margin-bottom: 5px !important;
    color: #6B778C;
}

.FormSubHEader {
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 22px !important;
    color: #0A1F41;
}

.AdduserMainBtn {
    font-size: 18px !important;
    font-weight: 500 !important;
    line-height: 22px !important;
    text-transform: none !important;
}

.AdduserMainBtn span {
    margin-right: 7px;
}

.AddUSerBtnSection {
    display: flex;
    justify-content: flex-end;
    column-gap: 15px;
}

.AddUSerBtnSection button {
    width: 178px;
    height: 48px;
}

.MainTable {
    border-collapse: separate !important;
    border-spacing: 0px 17px !important;
}

.tableHeadRow {
    width: 100%;
    /* display: flex !important; */
    align-items: center;
    background: #d7edff;
    border-radius: 10px !important;
}

.tableHeadCell {
    font-size: 15px !important;
    font-weight: 600 !important;
    line-height: 22px !important;
    text-align: left;
    color: #0a1f41 !important;
    min-width: 25%;
    border: none !important;
    padding: 16px !important;
    width: 30%;
}

.tableHEadercelldiv {
    display: flex;
    align-items: center;
}

.displayFlex {
    display: flex;
    align-items: center;
    column-gap: 5px;
}

.table_bg1 {
    width: 100%;
    align-items: center;
    background: #fff;
    border-radius: 10px !important;
    padding: 10px;
}

.table_bg1 td {
    border-bottom: none !important;
    min-width: 25%;
}

.menuPaper {
    height: 250px;
}

.moreMemberText {
    font-size: 14px !important;
    font-weight: 700 !important;
    line-height: 18px !important;
    cursor: pointer;
    color: #227DC3;
}

.menuItems {
    min-width: 132px !important;
}

.menuItems:hover {
    background-color: #EAF6FF !important;
}

.menuListHolder ul {
    padding: 0 !important;
}

.viewPageHeadContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ViewPageMainContainer {
    height: max-content;
    padding: 20px;
}

.viewPageHeadtitle {
    font-size: 18px !important;
    font-weight: 600 !important;
    line-height: 25px !important;
    color: #0A1F41;
}

.viewPageSubTitle {
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 22px !important;
    color: #0A1F41;
}

.viewPageNrmlText {
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 21px !important;
    color: #0A1F41;
}

.viewPageEditBtn {
    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: 17px !important;
    text-transform: none !important;
}

.viewPageEditBtn svg {
    width: 18px;
}

.CalendarRangeContainer div>div {
    max-width: none !important;
}