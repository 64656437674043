.header_maindiv {
  justify-content: space-between;
  align-items: center;
  background: white;
  height: 70px;
  padding: 0 40px 0 10px;
}

.second_maindiv {
  align-items: center !important;
  column-gap: 20px;
}

.resetPAsswordMenu {
  padding-left: 11px !important;
}

.resetPAsswordMenu svg {
  width: 16px;
  height: 16px;
  margin: 0 10px;
}

.header_MenuIcon {
  column-gap: 20px;
  align-items: center !important;
  margin-left: 105px;
}

.header_MenuIconMainText {
  font-size: 18px !important;
  color: #000 !important;
  font-weight: 600 !important;
  cursor: pointer !important;
}

.notificationBar svg path {
  fill: #0a1f41;
}

.HeaderLogoImage {
  margin-left: 10px;
}

.AvatarDiv {
  align-items: center;
  column-gap: 10px;
  cursor: pointer !important;
}

li svg {
  color: unset;
  margin-right: 10px;
}

.MenuItems ul {
  width: 200px !important;
}

.header_flex {
  display: flex;
}

.nabBarContainer {
  /* display: grid !important;
      align-content: space-around !important; */
  width: 100%;
  padding-left: 0;
  background: white;
}

.nabBarContainer a,
li {
  height: 50px;
}

.nabBarContainer li {
  padding-left: 15px;
  list-style-type: none;
}

.activebtn {
  background: linear-gradient(
    90deg,
    rgba(19, 111, 178, 0.2) 0%,
    rgba(19, 111, 178, 0) 100%
  );
  display: block;
}

.activebtn .linkburron {
  color: #1273b8 !important;
}

.activebtn .linkburron .activeIcon svg path {
  stroke: #1273b8 !important;
}

.activebtn .linkburron .activeIcon1 svg path {
  fill: #1273b8 !important;
}

.linkbtn {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.linkburron {
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 19px !important;
  color: #0a1f41 !important;
  margin-top: 8px !important;
  text-transform: capitalize !important;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.header {
  flex-shrink: 0;
}

.sidebar {
  flex-shrink: 0;
  width: 264px;
  background-color: #f0f0f0;
  overflow-y: auto;
}

.sidebar::-webkit-scrollbar {
  display: none;
}

.content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.anchortag {
  text-decoration: none !important;
}

.scrollableOutlet {
  flex-grow: 1;
  overflow-y: scroll;
  background-color: #f4f5f570;
  padding: 20px;
}

.clearAll {
  color: #1273b8 !important;
  font-size: 12px;
  font-weight: 600;
  /* text-align: right; */
  /* width: 100%; */
  display: flex;
  justify-content: flex-end;
  height: max-content;
}

.successPageSubText {
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  text-align: center !important;
  color: #6b778c;
}

.deleteConformationFirstDiv {
  height: 100px;
  margin: 30px;
}

.closebtn {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  font-size: 20px !important;
}

.scrollableOutlet::-webkit-scrollbar {
  display: none;
}

.mainLayout {
  flex-grow: 1;
  display: flex;
  overflow: hidden;
}

.activeIcon svg path {
  stroke: none !important;
}

.deleteConformationFirstDiv {
  height: auto;
  margin: 30px;
}

.successPageSubText {
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  text-align: center;
  color: #6b778c;
}

.successPageMainText {
  font-size: 24px !important;
  font-weight: 600 !important;
  line-height: 36px !important;
  text-align: center;
  color: #000000;
}

.addMemberModalbtnSection {
  display: flex;
  margin: 25px auto 20px;
  justify-content: space-between;
  width: 100%;
}

.addMemberModalbtnSection button {
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 22px !important;
  width: 48%;
  padding: 11px 24px;
  text-transform: capitalize !important;
}

.HeaderUser_designation {
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  color: #6b778c;
}

.paginationbtn {
  padding: 0 !important;
  height: 32px;
  width: 32px;
  background: white !important;
  border: none !important;
}

.paginationbtn svg g {
  opacity: 1 !important;
}

.HeaderSmallMenu {
  column-gap: 10px !important;
  margin-left: 15px !important;
}

.smallSidebar {
  flex-shrink: 0;
  background-color: #f0f0f0;
  overflow-y: auto;
  width: 65px !important;
}

.smallSidebar::-webkit-scrollbar {
  display: none;
}

.smallSidebar button {
  min-width: 0 !important;
}

.smallSidebar svg {
  margin-right: 0 !important;
}

.tooltip_arrow {
  position: absolute;
  top: -10px;
  left: 50%;
  margin-left: -3px;
  border-width: 13px;
  border-style: solid;
  border-color: transparent transparent #f5f5f9 transparent;
}

.notificationBar {
  color: white;
}

.notificationBar > div > div > div {
  max-width: unset;
  width: 450px;
  height: 390px;
}

.notificationBar > div > div {
  left: -10px !important;
}

.TooltipHEader {
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 19px !important;
  color: #0a1f41 !important;
  padding: 10px;
}

.NoImaheContainer {
  height: 345px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
}

.HeaderNotificationButton:hover {
  border: none !important;
}

.menuItem {
  display: flex !important;
  justify-content: space-between !important;
}

.NotificationItemMainDiv {
  position: relative;
  display: flex;
  padding: 8px 10px;
  column-gap: 10px;
  min-height: 85px;
}

.hover_button_container {
  display: none;
  position: absolute;
  top: 60px;
  right: 0;
}

.NotificationItemMainDiv:hover .hover_button_container {
  display: block;
}

.NotificationItemMainDiv:hover {
  background-color: #eaf6ff !important;
}

.notificationMessgeText {
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  color: #6b778c !important;
}

.notificationTime {
  position: absolute;
  right: 7px;
  bottom: 25px;
}

.TooltipmarkText {
  text-transform: none !important;
  font-size: 13px !important;
}

.unseencount {
  margin-left: 8px;
  background: #227dc3;
  padding: 2px 4px;
  border-radius: 4px;
  color: white;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0.01em;
}

.viewAllbtnSection {
  display: flex;
  justify-content: center;
  position: absolute;
  width: 100%;
  bottom: 0;
}

.notificationListContainer {
  height: 310px;
  overflow-y: scroll;
}

.NewBtn {
  position: absolute !important;
  right: 15px;
  top: 10px;
  width: 42px;
  height: 26px;
  min-width: 0 !important;
}

.notificationListContainer::-webkit-scrollbar {
  display: none;
}

.markAsReadBtn {
  position: absolute !important;
  right: 0px;
  bottom: 0px;
}

.brudCrumb {
  /* width: 210px; */
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  margin-top: 15px;
  column-gap: 15px;
}

.brudCrumb svg {
  color: black;
}

.notificationHEader {
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 25px !important;
  color: #0a1f41;
}

.notificationHeaderContainer {
  display: flex;
  padding: 20px;
}

.mesgadeTExt {
  font-weight: 400 !important;
  color: #6b778c;
}

.TimeTextNotifivcation {
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  color: #6b778c;
}
