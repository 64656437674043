.MainTable {
    border-collapse: separate !important;
    border-spacing: 0px 17px !important;
}

.tableHeadRow {
    width: 100%;
    /* display: flex !important; */
    align-items: center;
    background: #d7edff;
    border-radius: 10px !important;
}

.tableHeadCell {
    font-size: 15px !important;
    font-weight: 600 !important;
    line-height: 22px !important;
    text-align: left;
    color: #0a1f41 !important;
    min-width: 25%;
    border: none !important;
    padding: 16px !important;
    width: 30%;
}

.tableHEadercelldiv {
    display: flex;
    align-items: center;
}

.displayFlex {
    display: flex;
    align-items: center;
    column-gap: 5px;
}

.table_bg1 {
    width: 100%;
    align-items: center;
    background: #fff;
    border-radius: 10px !important;
    padding: 10px;
}

.table_bg1 td {
    border-bottom: none !important;
    min-width: 25%;
}

.brudCrumb {
    /* width: 210px; */
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    margin-top: 15px;
    column-gap: 15px;
    cursor: pointer;
}

.brudCrumb svg {
    color: black;
}

.brudCrumb span:hover {
    text-decoration: underline;
    color: blue;
}

.AddNotificationMainBtn {
    font-size: 18px !important;
    font-weight: 500 !important;
    line-height: 22px !important;
    text-transform: none !important;
}

.AddNotificationMainBtn span {
    margin-right: 7px;
}

.NoDataImageMindiv {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.NoDataPageMainText {
    font-size: 24px !important;
    font-weight: 600 !important;
    line-height: 34px !important;
    color: #0A1F41;
    width: 535px;
    text-align: center;
}

.formLable {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 18px !important;
    margin-bottom: 5px !important;
    color: #6B778C;
}

.AddUSerBtnSection {
    display: flex;
    justify-content: flex-end;
    column-gap: 15px;
}

.AddUSerBtnSection button {
    width: 178px;
    height: 48px;
}

.newchipAdderContainer {
    display: grid !important;
    grid-template-columns: 84% 15%;
    column-gap: 1%;
    width: 100%;
}

.chips_container {
    display: flex;
    column-gap: 10px;
}

.DropZoneContainer {
    border: 1px dashed rgba(81, 111, 144, 0.4);
    height: fit-content;
}

.DropZone {
    min-height: 96px;
    height: auto;
    background: #f3f8fc;
    border-radius: 8px;
    display: flex;
    column-gap: 10px;
    align-items: center;
}

.DropzoneBtn {
    padding: 8px 22px !important;
    border-radius: 4px !important;
    width: 150px;
    margin-left: 25px !important;
}

.imageContainer {
    display: flex;
    column-gap: 2%;
    padding: 15px 20px;
    margin-bottom: 30px;
}

.subImageContainer {
    height: auto;
    width: 250px;
}

.imageText {
    display: flex;
    justify-content: space-between;
}

.mapandImageContainer {
    display: flex;
}

.documentImagesContiner {
    display: flex;
    column-gap: 10px;
}

.documentImagesContiner img {
    width: 40px;
    height: 40px;
    border-radius: 5px;
}

.documentImagesContiner p {
    color: white;
    width: 40px;
    height: 40px;
    background: #227DC3;
    padding: 10px;
    border-radius: 5px;
}

.TableCellMainText {
    font-size: 15px !important;
    font-weight: 400 !important;
    line-height: 18px !important;
    color: #0A1F41;
}

.SomeOtheText {
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 16px !important;
    color: #6B778C;
}

.ReadMoreToggleButton {
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    color: #227DC3;
}

.SomeOtherTextclosedBy {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    color: #6B778C;
}

.TableCellMainText1 {
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 22px !important;
    color: #0A1F41;
}

.SomeOtheText1 {
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 22px !important;
    color: #4E5365;
}

.feedbackEmojiContainer {
    display: flex;
    column-gap: 5px;
}

.menuItems {
    min-width: 132px !important;
}

.menuItems:hover {
    background-color: #EAF6FF !important;
}

.TableCellblogheading {
    font-weight: 700 !important;
    font-size: 16px !important;
}

.AddDepMainBtn {
    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: 17px !important;
    color: #227DC3 !important;
}

.AddDepMainBtn svg {
    width: 16px;
    margin-right: 5px;
}

.AddDepMainBtn svg path {
    fill: #227DC3 !important;
}