.TableHead {
    display: flex !important;
    flex-direction: column;
}

.MainTable {
    border-collapse: separate !important;
    border-spacing: 0px 17px !important;
}

.tableContainer {
    width: 100%;
    overflow-x: scroll;
}

.teamMemberSearch {
    width: 360px;
}

.HeaderInputContainer {
    display: flex;
    justify-content: space-between;
}

.teamMemberReport {
    background-color: white !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 21px !important;
    width: 234px;
    height: 48px;
    display: inline-flex !important;
    text-transform: none !important;
    justify-content: space-between !important;
}

.secondImportText {
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 22px !important;
    color: #0A1F41;
}

.teamMemberSearch>div {
    border-radius: 25px;
    background: white;
    padding: 3px 14px;
}

/* .tableContainer::-webkit-scrollbar {
    display: none;
} */

.table_bg {
    width: 100%;
    align-items: center;
    background: white;
    border-radius: 10px !important;
    padding: 10px;
}

.table_bg1 {
    width: 100%;
    align-items: center;
    background: #fff;
    border-radius: 10px !important;
    padding: 10px;
}

.table_bg1 td {
    min-width: 180px;
}

.SubTable {
    background: #F3F8FC;
    border-radius: 10px !important;
}

.tableHeadRow {
    width: 100%;
    /* display: flex !important; */
    align-items: center;
    background: #d7edff;
    border-radius: 10px !important;
}

.tableHeadCell {
    font-size: 15px !important;
    font-weight: 600 !important;
    line-height: 22px !important;
    text-align: left;
    color: #0a1f41 !important;
    min-width: 180px;
    border: none !important;
    padding: 16px !important;
}


.tableHEadercelldiv {
    display: flex;
    align-items: center;
}

.TableSubRow td,
.TableSubRow th {
    padding: 16px !important;
    border-bottom: none !important;
}

.TableSubRow th {
    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: 19px !important;
    color: #0A1F41;
}

.someSmallText {
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 16px !important;
    color: #6B778C;
}

.form_lable {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 18px !important;
    color: #6B778C;
}

.datePicker1 {
    position: relative;
}

.datePicker1>div {
    width: 100%;
}

.datePicker1>div>div>input {
    padding: 8px 10px;
    border-radius: 4px;
    border: 1px solid #bfd1cf;
    width: 100%;
}

.datePickerCalendarIcon {
    right: 5px;
    position: absolute;
    top: 10px;
}

.customerPagebtnSection {
    display: flex;
    column-gap: 15px;
}

.customerPagebtnSection button {
    width: 50% !important;
    text-transform: none !important;
    padding: 8px 24px;
    font-size: 18px !important;
    font-weight: 500 !important;
    line-height: 22px !important;
}

.modalHEadingCustomerFilter {
    font-size: 20px !important;
    font-weight: 600 !important;
    line-height: 28px !important;
    color: #0A1F41;
}

.brudCrumb {
    /* width: 210px; */
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    margin-top: 10px;
    column-gap: 15px;
    cursor: pointer;
}

.invoiceviewbtns {
    font-size: 18px !important;
    font-weight: 500 !important;
    line-height: 22px !important;
}

.brudCrumb svg {
    color: black;
}

.PageImage {
    display: flex;
    justify-content: space-between;
    padding: 20px 40px;
}

.imageBackGround {
    background: #f3faff;
}

.imageContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 10px;
}

.InvoiceMenuOptions ul {
    padding: 0 !important;
}

.InvoiceMenuOptions li:hover {
    background-color: #EAF6FF !important;
    color: #227DC3 !important;
    font-weight: 600;
}

.invoiceBtnSection {
    display: flex;
    justify-content: center;
    margin: 30px 0;
    column-gap: 30px;
}

.invoiceBtnSection button {
    width: 220px;
    height: 46px;
    text-transform: none !important;
}

.InvoicePdfMainHEader {
    font-size: 24px !important;
    font-weight: 600 !important;
    line-height: 29px !important;
    color: #0A1F41;
}

.AddressDetails {
    display: grid;
    grid-template-columns: auto auto;
    margin: 30px 0 0;
    column-gap: 15px;
    row-gap: 10px;
}

.InvoicePdfSubHEader {
    font-size: 20px !important;
    font-weight: 600 !important;
    line-height: 24px !important;
    color: #0A1F41;
}

.InvoicePdfSubHEader1 {
    font-size: 18px !important;
    font-weight: 400 !important;
    line-height: 25px !important;
    color: #0A1F41;
}

.invoiceCommonText {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    color: #0A1F41;
}

.invoiceImpText {
    font-weight: 600 !important;
}

.CardMain {
    box-shadow: 1px 6px 44px 0px #03022912 !important;
}

.TransParentHeader {
    border-top-right-radius: 15px;
    display: grid;
    grid-template-columns: 65% 35%;
    padding: 20px;
    width: 100%;
    margin: 0 auto;
    background-color: #EEEBFE;
    border-top-left-radius: 15px;
}

.AmountContainer {
    display: grid;
    grid-template-columns: 65% 35%;
    padding: 20px 0;
}

.invoicePageLine {
    margin: 0 !important;
}