.TabPanel > div {
  padding: 24px 0;
}

.TabPanel1 > div {
  padding: 0 !important;
}

.HeadTabButton button {
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 22px !important;
}

.teamMemberSearch {
  width: 360px;
}

.secondImportText {
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 22px !important;
  color: #0a1f41;
}

.teamMemberSearch > div {
  border-radius: 25px;
  background: white;
  padding: 3px 14px;
}

.HeaderInputContainer {
  display: flex;
  justify-content: space-between;
}

.MainHeaderCustomerButton {
  text-transform: none !important;
  display: inline-flex !important;
  column-gap: 10px !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  padding: 6px 20px !important;
  letter-spacing: 0px;
  text-align: center !important;
  margin-left: 20px !important;
}

.TableHead {
  display: flex !important;
  flex-direction: column;
}

.MainTable {
  border-collapse: separate !important;
  border-spacing: 0px 17px !important;
}

.tableContainer {
  width: 100%;
  overflow-x: scroll;
}

/* .tableContainer::-webkit-scrollbar {
    display: none;
} */

.table_bg {
  width: 100%;
  align-items: center;
  background: white;
  border-radius: 10px !important;
  padding: 10px;
}

.table_bg1 {
  width: 100%;
  align-items: center;
  background: #fff;
  border-radius: 10px !important;
  padding: 10px;
}

.SubTable {
  background: #f3f8fc;
  border-radius: 10px !important;
}

.teamMemberReport {
  background-color: white !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 21px !important;
  width: 284px;
  height: 48px;
  display: inline-flex !important;
  text-transform: none !important;
  justify-content: space-between !important;
}

.tableHeadRow {
  width: 100%;
  /* display: flex !important; */
  align-items: center;
  background: #d7edff;
  border-radius: 10px !important;
}

.tableHeadCell {
  font-size: 15px !important;
  font-weight: 600 !important;
  line-height: 22px !important;
  text-align: left;
  color: #0a1f41 !important;
  min-width: 180px;
  border: none !important;
  padding: 16px !important;
}

.tableHEadercelldiv {
  display: flex;
  align-items: center;
}

.brudCrumb {
  /* width: 210px; */
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  margin-top: 15px;
  column-gap: 15px;
  cursor: pointer;
}

.brudCrumb svg {
  color: black;
}

.customerProfileCardContainer {
  display: grid;
  grid-template-columns: 49% 49%;
  column-gap: 2%;
}

.customerCardMainDiv {
  display: flex;
  align-items: center;
  column-gap: 20px;
  position: relative;
  height: 100%;
}

.companyDetailsContainer {
  display: grid;
  grid-template-columns: 50% 50%;
}

.EditButton {
  position: absolute;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 19px !important;
  right: 0;
  top: 5px;
  color: #227dc3;
  cursor: pointer;
}

.cardHeading {
  font-size: 24px !important;
  font-weight: 500 !important;
  line-height: 31px !important;
  color: #0a1f41;
}

.cardSubHeading {
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 21px !important;
  color: #0a1f41;
}

.displayFlex {
  display: flex;
  flex-wrap: wrap !important;
  align-items: center;
  column-gap: 5px;
}

.companyIdText {
  font-size: 20px !important;
  font-weight: 400 !important;
  line-height: 30px !important;
  color: #0a1f41;
}

.modalHEadingCustomerFilter {
  font-size: 20px !important;
  font-weight: 600 !important;
  line-height: 28px !important;
  color: #0a1f41;
}

.TableSubRow td,
.TableSubRow th {
  padding: 16px !important;
  border-bottom: none !important;
}

.TableSubRow th {
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 19px !important;
  color: #0a1f41;
}

.table_bg td {
  border-bottom: none !important;
  min-width: 180px;
}

.table_bg1 td {
  border-bottom: none !important;
  min-width: 180px;
}

.someSmallText {
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 16px !important;
  color: #6b778c;
}

.AddMemberHeading {
  background: #dff1ff;
  padding: 23px 0;
}

.AddMemberHeading > p {
  width: 85%;
  margin: auto;
  font-size: 24px !important;
  font-weight: 600 !important;
  line-height: 34px !important;
}

.closebtn {
  position: absolute !important;
  right: 5px;
  top: 5px;
}

.closebtn svg {
  font-size: 21px;
  font-weight: 800;
  color: black;
}

.depFormContainer {
  display: flex;
  flex-direction: column;
  height: auto;
  row-gap: 10px;
  width: 90%;
  margin: 0px auto;
}

.formContainer {
  height: 400px;
  overflow-y: scroll;
}

.formContainer::-webkit-scrollbar {
  display: none;
}

.form_lable {
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  color: #999999;
}

.datePicker1 {
  position: relative;
}

.datePicker1 > div {
  width: 100%;
}

.datePicker1 > div > div > input {
  padding: 8px 10px;
  border-radius: 4px;
  border: 1px solid #bfd1cf;
  width: 100%;
}

.datePickerCalendarIcon {
  right: 5px;
  position: absolute;
  top: 10px;
}

.customerPagebtnSection {
  display: flex;
  column-gap: 15px;
}

.customerPagebtnSection button {
  width: 50% !important;
  text-transform: none !important;
  padding: 8px 24px;
}

.FormInputsContainer {
  display: grid;
  grid-template-columns: 32% 32% 32%;
  column-gap: 2%;
  row-gap: 25px;
}

.inviteCustomerMainHeading {
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 25px !important;
  color: #0a1f41;
}

.InviteCustmerBtnSection {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  column-gap: 15px;
}

.invitePAgeMainBtn {
  height: 40px;
  width: 179px;
  text-transform: none !important;
  font-weight: 600 !important;
}

.imagePageText {
  margin-top: 20px !important;
  text-align: center;
  font-size: 24px !important;
  font-weight: 600 !important;
  line-height: 34px !important;
  width: 75%;
}

.successContaoner {
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.successPageSubText {
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  text-align: center;
  color: #6b778c;
}

.CalendarRangeContainer div > div {
  max-width: none !important;
}

.successPageMainDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 20px;
}

.AdduserMainBtn {
  font-size: 18px !important;
  font-weight: 500 !important;
  line-height: 22px !important;
  text-transform: none !important;
}

.AdduserMainBtn span {
  margin-right: 7px;
}

.AvatarContainer {
  display: flex !important;
  align-items: center;
  column-gap: 7px;
}

.CustomerNameText {
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  color: #0a1f41;
}

.ChartContainer {
  position: relative;
}

.hoverDiv {
  display: none;
  position: absolute;
  top: 25px;
  left: 80px;
  width: max-content;
  border-radius: 4px;
  background-color: #e3e3e3;
  border: 1px solid #000000;
  padding: 10px;
  z-index: 2;
}

.ChartContainer:hover .hoverDiv {
  display: block;
}

.sendInvoiceModalClose {
  position: absolute;
  right: 0;
}

.sendInvoiceModalClose svg {
  color: black;
  width: 25px;
  height: 25px;
}

.ModalHeadingContainer {
  height: 82px;
  background: #dff1ff;
  display: flex;
  align-items: center;
  padding-left: 25px;
}

.modalHeading {
  font-size: 24px !important;
  font-weight: 600 !important;
  line-height: 34px !important;
  letter-spacing: 0em;
  text-align: left;
  color: #0a1f41;
}

.ValidatorForm {
  width: 90% !important;
  margin: auto;
}

.noDataCardContainer {
  height: 75vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.stepper {
  display: flex;
  flex-direction: column;
}

.step {
  display: flex;
  align-items: center;
  position: relative;
  column-gap: 15px;
}

.step_number {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: transparent;
  border: 2px solid #ccc;
  color: #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
}

.stepLableContainer {
  position: absolute;
  left: 40px;
  top: 2px;
}

.steppersMainContainer {
  margin: 0 0 25px;
}

.step_label {
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 20px !important;
  letter-spacing: 0px !important;
  color: #0a1f41;
  padding: 5px 10px;
  border-radius: 75px;
}

.sub_step_label {
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #0a1f41;
}

.step.completed .step_number {
  background-color: green;
  border-color: green;
  color: #fff;
}

.RouteLine {
  width: 5px;
  height: 50px;
  background-color: #ccc;
  margin-left: 99px;
}

.Completed {
  background-color: green;
}

.addMembersuccess {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  row-gap: 10px;
}

.emailFeild {
  /* width: 160px !important; */
  overflow: hidden;
}

.successMainText {
  font-size: 24px !important;
  font-weight: 600 !important;
  line-height: 34px !important;
  text-align: center;
  color: #0a1f41;
}

.successSubText {
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  text-align: center;
  color: #6b778c;
}
