.brudCrumb {
    /* width: 210px; */
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    margin-top: 15px;
    column-gap: 15px;
}

.brudCrumb svg {
    color: black;
}

.MainHeader {
    font-size: 18px !important;
    font-weight: 600 !important;
    line-height: 25px !important;
    color: #0A1F41;
}

.form_label {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 18px !important;
    color: #6B778C;
}

.passwordInputContainer {
    position: relative;
    width: 345px;
}

.showPassword12 {
    position: absolute;
    top: 24px;
    right: 0;
}

.showPassword12 button {
    min-width: 0;
}

.ResetPasswordButton {
    font-size: 18px !important;
    font-weight: 500 !important;
    line-height: 22px !important;
    text-transform: none !important;
}

.ProfileNameMain {
    font-size: 32px !important;
    font-weight: 600 !important;
    line-height: 45px !important;
    color: #0A1F41;
}

.ProfileNameSub {
    font-size: 24px !important;
    font-weight: 400 !important;
    line-height: 34px !important;
    color: #0A1F41;
}

.containerLable {
    font-size: 24px !important;
    font-weight: 400 !important;
    line-height: 34px !important;
    color: #6B778C;
}

.containerValue {
    font-weight: 600 !important;
    color: #0A1F41;
}

.EditProfileBtnSection {
    display: flex;
    margin-top: 10px;
    justify-content: flex-end;
    column-gap: 15px;
}

.closebtn {
    position: absolute !important;
    right: 5px;
    top: 5px;
}

.closebtn svg {
    font-size: 21px;
    font-weight: 800;
    color: black;
}

.addMembersuccess {
    margin: 15px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 92%;
    justify-content: center;
    height: 90%;
    text-align: center;
    row-gap: 10px;
}

.addMembersuccess h4 {
    margin-top: 40px;
}

.imagePageText {
    margin-top: 20px !important;
    text-align: center;
    font-size: 24px !important;
    font-weight: 600 !important;
    line-height: 34px !important;
    width: 75%;
}

.successPageSubText {
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    text-align: center;
    color: #6b778c;
}