.ReportTextFieldsContainer {
    display: grid;
    grid-template-columns: 26% 26% 26% 16%;
    column-gap: 2%;
}

.datePicker1 {
    position: relative;
}

.datePicker1>div {
    width: 100%;
}

.datePicker1>div>div>input {
    padding: 8px 10px;
    border-radius: 4px;
    border: 1px solid #bfd1cf;
    width: 100%;
}

.teamPageHeading2 {
    margin-top: 5px !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    text-align: left !important;
    color: #0a1f41;
}

.textLable {
    color: #6b778c;
    margin-bottom: 10px !important;
}

.datePickerCalendarIcon {
    right: 5px;
    position: absolute;
    top: 10px;
}

.teamMemberBtn {
    width: 100%;
    margin-top: 25px !important;
    text-transform: capitalize !important;
}

.teamMemberBtn svg {
    width: 15px !important;
}

.RepotsearchIcon svg {
    margin-right: 10px;
}

.RepotsearchIcon svg path {
    fill: white;
}

.DownloadTypeBtn {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

.DownloadTypeBtn>div {
    background: white;
}

.TableContainer {
    overflow-x: scroll;
}

/* .TableContainer::-webkit-scrollbar {
    display: none;
} */

.MainTable {
    border-collapse: separate !important;
    border-spacing: 0px 17px !important;
}

.tableHeadRow {
    width: 100%;
    /* display: flex !important; */
    align-items: center;
    background: #d7edff;
    border-radius: 10px !important;
}

.tableHeadCell {
    font-size: 15px !important;
    font-weight: 600 !important;
    line-height: 22px !important;
    text-align: left;
    color: #0a1f41 !important;
    min-width: 180px;
    border: none !important;
    padding: 16px !important;
}

.tableHEadercelldiv {
    display: flex;
    align-items: center;
}

.displayFlex {
    display: flex;
    align-items: center;
    column-gap: 5px;
}


.table_bg1 {
    width: 100%;
    align-items: center;
    background: #fff;
    border-radius: 10px !important;
    padding: 10px;
}

.table_bg1 td {
    border-bottom: none !important;
    min-width: 190px;
}

.TableCellMainText {
    font-size: 15px !important;
    font-weight: 400 !important;
    line-height: 18px !important;
    color: #0A1F41;
}

.SomeOtheText {
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 16px !important;
    color: #6B778C;
}