.teamMemberSearch {
    width: 360px;
}

.teamMemberSearch>div {
    border-radius: 25px;
    background: white;
    padding: 3px 14px;
}

.HeaderSearchBar {
    display: flex;
    justify-content: space-between;
}

.TableContainer {
    overflow-x: scroll;
}

/* .TableContainer::-webkit-scrollbar {
    display: none;
} */

.MainTable {
    border-collapse: separate !important;
    border-spacing: 0px 17px !important;
}

.tableHeadRow {
    width: 100%;
    /* display: flex !important; */
    align-items: center;
    background: #d7edff;
    border-radius: 10px !important;
}

.tableHeadCell {
    font-size: 15px !important;
    font-weight: 600 !important;
    line-height: 22px !important;
    text-align: left;
    color: #0a1f41 !important;
    min-width: 180px;
    border: none !important;
    padding: 16px !important;
}

.tableHEadercelldiv {
    display: flex;
    align-items: center;
}

.displayFlex {
    display: flex;
    align-items: center;
    column-gap: 5px;
}

.table_bg1 {
    width: 100%;
    align-items: center;
    background: #fff;
    border-radius: 10px !important;
    padding: 10px;
}

.table_bg1 td {
    border-bottom: none !important;
    min-width: 180px;
}

.TableCellMainText {
    font-size: 15px !important;
    font-weight: 400 !important;
    line-height: 18px !important;
    color: #0A1F41;
}

.SomeOtheText {
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 16px !important;
    color: #6B778C;
}

.CalendarRangeContainer div>div {
    max-width: none !important;
}